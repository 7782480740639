import { motion, AnimatePresence } from 'framer-motion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';

// Couleurs Avengers
const avengersColors = {
  red: '#a50000',
  blue: '#3268bd',
  orange: '#ff9500',
  purple: '#460061'
};

type SubMenuItem = {
  title: string;
  description: string;
  icon: string;
  isImage?: boolean;
};

type NavItem = {
  label: string;
  href: string;
  description: string;
  submenu?: SubMenuItem[];
};

const navigationItems: NavItem[] = [
  {
    label: "Vision",
    href: "vision",
    description: "Notre mission pour l'IA éthique",
    submenu: [
      {
        title: "Notre Approche",
        description: "Une IA éthique et souveraine",
        icon: "/images/component-icon.png",
        isImage: true
      },
      {
        title: "Valeurs",
        description: "Excellence et innovation responsable",
        icon: "/images/showcase-icon.png",
        isImage: true
      }
    ]
  },
  {
    label: "Solutions",
    href: "solutions",
    description: "Technologies d'avant-garde",
    submenu: [
      {
        title: "Edge Intelligence",
        description: "IA locale haute performance",
        icon: "/images/edge-intelligence-icon.png",
        isImage: true
      },
      {
        title: "Genius Framework",
        description: "Apprentissage adaptatif",
        icon: "/images/genius-framework-icon.png",
        isImage: true
      },
      {
        title: "Music AI",
        description: "Innovation musicale",
        icon: "/images/music-ai-icon.png",
        isImage: true
      }
    ]
  },
  {
    label: "Swiss Avengers",
    href: "swiss-avengers",
    description: "L'élite de l'IA suisse",
    submenu: [
      {
        title: "Infrastructure de pointe",
        description: "Notre environnement technologique",
        icon: "/images/swiss-shield-icon.png",
        isImage: true
      },
      {
        title: "L'équipe d'élite",
        description: "Experts en IA et éthique",
        icon: "/images/swiss-helmet-icon.png",
        isImage: true
      }
    ]
  },
  {
    label: "Impact",
    href: "impact",
    description: "Notre contribution",
    submenu: [
      {
        title: "Innovations",
        description: "Avancées technologiques",
        icon: "/images/local-innovation-icon.png",
        isImage: true
      },
      {
        title: "Éthique",
        description: "Engagement responsable",
        icon: "/images/sustainable-impact-icon.png",
        isImage: true
      }
    ]
  },
  {
    label: "Blog",
    href: "/blog",
    description: "Articles et innovations"
  }
];

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const menuRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const handleNavigation = (href: string, e: React.MouseEvent) => {
    e.preventDefault();
    setOpenMenu(null);
    
    if (href.startsWith('/')) {
      navigate(href);
    } else {
      if (location.pathname !== '/') {
        navigate('/', { state: { scrollTo: href } });
      } else {
        const element = document.getElementById(href);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        } else {
          navigate('/');
        }
      }
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (openMenu && menuRefs.current[openMenu] && !menuRefs.current[openMenu]?.contains(e.target as Node)) {
      setOpenMenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openMenu]);

  return (
    <nav className="fixed top-0 left-0 right-0 z-40 mt-[34px]" style={{
      background: 'rgba(255, 255, 255, 0.8)',
      backdropFilter: 'blur(20px) saturate(180%)',
      WebkitBackdropFilter: 'blur(20px) saturate(180%)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.06)'
    }}>
      <div className="container mx-auto px-6 py-3">
        <div className="flex items-center justify-between">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="hardware-accelerated"
          >
            <Link to="/" onClick={(e) => { e.preventDefault(); navigate('/'); }}>
              <img src="/images/logo.png" alt="Infinity Cloud" className="h-8" style={{ display: 'block' }} />
            </Link>
          </motion.div>
          
          <div className="hidden md:flex items-center space-x-8">
            {navigationItems.map((item) => (
              <div 
                key={item.label} 
                className="relative"
                ref={el => menuRefs.current[item.label] = el}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (item.submenu) {
                      setOpenMenu(openMenu === item.label ? null : item.label);
                    } else {
                      handleNavigation(item.href, e);
                    }
                  }}
                  className="py-2 px-3 rounded-full text-base font-bold inline-flex items-center transition-colors"
                  style={{ 
                    background: `linear-gradient(to right, ${avengersColors.blue}, ${avengersColors.red}, ${avengersColors.purple})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    color: avengersColors.blue, // Fallback
                    fontSize: '1.1rem'
                  }}
                >
                  <span>{item.label}</span>
                  {item.submenu && (
                    <motion.svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 ml-1 opacity-80 transform transition-all duration-300"
                      animate={{ rotate: openMenu === item.label ? 180 : 0 }}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      style={{ 
                        color: avengersColors.purple,
                        WebkitTextFillColor: avengersColors.purple
                      }}
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </motion.svg>
                  )}
                </button>
                
                <AnimatePresence>
                  {item.submenu && openMenu === item.label && (
                    <motion.div 
                      className="absolute top-full left-1/2 transform -translate-x-1/2 pt-2 z-50"
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                      style={{ display: 'block', visibility: 'visible', opacity: 1 }}
                    >
                      <div className="dropdown-menu p-2 rounded-2xl min-w-[280px] grid gap-1">
                        {item.submenu.map((subitem, index) => (
                          <motion.button
                            key={subitem.title}
                            onClick={(e) => handleNavigation(item.href, e)}
                            className="dropdown-item flex items-center space-x-3 p-2.5 rounded-xl w-full"
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ 
                              opacity: 1, 
                              y: 0,
                              transition: { delay: index * 0.05 }
                            }}
                          >
                            {subitem.isImage ? (
                              <img 
                                src={subitem.icon} 
                                alt={subitem.title} 
                                className="w-10 h-10 rounded-full object-cover"
                                style={{ display: 'block', visibility: 'visible', opacity: 1 }}
                              />
                            ) : (
                              <span className="flex-shrink-0 w-10 h-10 rounded-full flex items-center justify-center text-lg"
                                style={{
                                  background: `linear-gradient(135deg, ${avengersColors.blue}, ${avengersColors.purple})`,
                                  color: 'white'
                                }}
                              >
                                {subitem.icon}
                              </span>
                            )}
                            <div className="flex-1 text-left">
                              <p className="text-base font-semibold" style={{ color: avengersColors.red }}>
                                {subitem.title}
                              </p>
                              <p className="text-sm mt-0.5" style={{ color: avengersColors.blue }}>
                                {subitem.description}
                              </p>
                            </div>
                          </motion.button>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>

          <button
            onClick={(e) => handleNavigation('/contact', e)}
            className="apple-button py-1.5 px-5 text-sm font-bold"
            style={{
              background: `linear-gradient(to right, ${avengersColors.red}, ${avengersColors.purple})`,
              color: 'white',
              fontSize: '1rem',
              borderRadius: '25px',
              padding: '8px 18px'
            }}
          >
            Contact
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 
import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const GeniusFramework = () => {
  return (
    <div className="min-h-screen bg-white text-[#333333]">
      <Helmet>
        <title>Genius Framework | Infinity Cloud</title>
        <meta name="description" content="Genius Framework - Apprentissage adaptatif qui évolue avec vos besoins pour des solutions intelligentes" />
      </Helmet>

      {/* Hero Section */}
      <section className="pt-20 pb-16 overflow-hidden relative">
        <div 
          className="absolute inset-0 opacity-10 z-0" 
          style={{
            backgroundImage: `url('/images/genius-network.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(50px)'
          }}
        ></div>
        <div className="container mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              <span 
                style={{
                  background: 'linear-gradient(135deg, #5e5ce6, #bf5af2)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  paddingBottom: '0.15em',
                  lineHeight: 1.2
                }}
              >
                Genius Framework
              </span>
            </h1>
            <p className="text-xl text-[#666666] max-w-3xl mx-auto">
              Une plateforme d'apprentissage adaptative qui évolue en fonction de vos besoins pour créer des solutions intelligentes sur mesure
            </p>
            
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.3, duration: 0.6 }}
              className="mt-12 flex justify-center"
            >
              <img 
                src="/images/genius-network.png" 
                alt="Genius Framework Network" 
                className="w-full max-w-2xl rounded-2xl shadow-2xl"
                style={{ 
                  animation: 'float 6s ease-in-out infinite',
                  maxWidth: '80%'
                }}
              />
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Core Concept */}
      <section className="py-16 bg-[#f9f9f9]">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #5e5ce6, #bf5af2)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              Concept
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              Adaptation Intelligente Continue
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              Notre framework révolutionnaire utilise des algorithmes d'apprentissage continu pour s'adapter en temps réel à vos besoins spécifiques
            </p>
          </motion.div>

          <div className="flex flex-col md:flex-row gap-8 items-center">
            <motion.div
              initial={{ opacity: 0, x: -30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex-1 order-2 md:order-1"
            >
              <h3 className="text-2xl font-semibold mb-4">Apprentissage Personnalisé</h3>
              <p className="text-[#666666] mb-6">
                Le Genius Framework analyse en permanence vos données et interactions pour affiner ses modèles et offrir des réponses toujours plus précises et adaptées à votre contexte.
              </p>
              
              <h3 className="text-2xl font-semibold mb-4">Évolution Constante</h3>
              <p className="text-[#666666]">
                Contrairement aux solutions figées, notre framework évolue et s'améliore avec chaque interaction, garantissant des performances optimales dans un environnement en constante évolution.
              </p>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, x: 30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex-1 order-1 md:order-2 flex justify-center"
            >
              <img 
                src="/images/genius-adaptation.png" 
                alt="Adaptation intelligente" 
                className="rounded-2xl shadow-lg w-full max-w-md"
                style={{ animation: 'float 6s ease-in-out infinite 1s' }}
              />
            </motion.div>
          </div>
        </div>
      </section>

      {/* Key Features */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #5e5ce6, #bf5af2)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              Fonctionnalités
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              Technologies Innovantes
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              Un ensemble d'outils puissants conçus pour transformer votre expérience utilisateur
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <div 
                className="w-12 h-12 flex items-center justify-center rounded-full mb-4"
                style={{ background: 'linear-gradient(135deg, #5e5ce6, #bf5af2)' }}
              >
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Traitement Ultra-Rapide</h3>
              <p className="text-[#666666]">
                Algorithmes optimisés pour des performances exceptionnelles, même avec des volumes de données considérables
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <div 
                className="w-12 h-12 flex items-center justify-center rounded-full mb-4"
                style={{ background: 'linear-gradient(135deg, #5e5ce6, #bf5af2)' }}
              >
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Sécurité Avancée</h3>
              <p className="text-[#666666]">
                Protection de vos données avec chiffrement de bout en bout et respect total de la confidentialité
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <div 
                className="w-12 h-12 flex items-center justify-center rounded-full mb-4"
                style={{ background: 'linear-gradient(135deg, #5e5ce6, #bf5af2)' }}
              >
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Intégration Flexible</h3>
              <p className="text-[#666666]">
                Compatible avec vos systèmes existants grâce à des API ouvertes et une architecture modulaire
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* User-Centric Design */}
      <section className="py-16 bg-[#f9f9f9]">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row gap-8 items-center">
            <motion.div
              initial={{ opacity: 0, x: -30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex-1 flex justify-center"
            >
              <img 
                src="/images/genius-interface.png" 
                alt="Interface utilisateur" 
                className="rounded-2xl shadow-lg w-full max-w-md"
                style={{ animation: 'float 6s ease-in-out infinite 2s' }}
              />
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, x: 30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex-1"
            >
              <span 
                style={{
                  background: 'linear-gradient(135deg, #5e5ce6, #bf5af2)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
                className="text-xl font-medium inline-block mb-3"
              >
                Design Centré Utilisateur
              </span>
              <h2 className="text-3xl font-semibold mb-4">Une Interface Intuitive</h2>
              <p className="text-[#666666] mb-6">
                Le Genius Framework place l'humain au centre de sa conception, offrant une expérience utilisateur intuitive et agréable qui minimise la courbe d'apprentissage.
              </p>
              
              <ul className="space-y-3">
                {[
                  "Visualisations interactives des données",
                  "Navigation contextuelle intelligente",
                  "Interfaces adaptatives selon l'appareil",
                  "Personnalisation complète de l'expérience"
                ].map((item, index) => (
                  <motion.li 
                    key={index}
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.3, delay: 0.1 * index }}
                    className="flex items-start"
                  >
                    <svg className="w-5 h-5 mt-1 text-purple-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-[#666666]">{item}</span>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Technical Specifications */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #5e5ce6, #bf5af2)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              Spécifications
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              Architecture Technique
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              Une infrastructure robuste conçue pour des performances optimales et une évolutivité sans limite
            </p>
          </motion.div>

          <div className="bg-white rounded-[24px] shadow-lg p-8"
            style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <h3 className="text-xl font-semibold mb-3">Algorithmes</h3>
                <ul className="space-y-2 text-[#666666]">
                  <li>• Réseaux neuronaux profonds</li>
                  <li>• Apprentissage par renforcement</li>
                  <li>• Traitement du langage naturel avancé</li>
                  <li>• Systèmes d'inférence bayésienne</li>
                  <li>• Traitement d'image neuronal</li>
                </ul>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <h3 className="text-xl font-semibold mb-3">Infrastructure</h3>
                <ul className="space-y-2 text-[#666666]">
                  <li>• Architecture distribuée</li>
                  <li>• Scalabilité horizontale automatique</li>
                  <li>• Traitement en temps réel</li>
                  <li>• Haute disponibilité (99.99%)</li>
                  <li>• Conteneurisation Kubernetes native</li>
                </ul>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <h3 className="text-xl font-semibold mb-3">Compatibilité</h3>
                <ul className="space-y-2 text-[#666666]">
                  <li>• API REST et GraphQL</li>
                  <li>• Intégration multi-cloud</li>
                  <li>• SDK pour tous frameworks majeurs</li>
                  <li>• Support PWA complet</li>
                  <li>• Connecteurs ETL universels</li>
                </ul>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      {/* Call To Action */}
      <section className="py-16 bg-gradient-to-r from-[#5e5ce6] to-[#bf5af2] text-white">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl font-bold mb-6">Prêt à transformer votre approche d'IA?</h2>
            <p className="max-w-2xl mx-auto text-lg mb-8 text-white/90">
              Découvrez comment le Genius Framework peut s'adapter à vos besoins spécifiques et révolutionner votre utilisation de l'intelligence artificielle.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-white text-purple-700 font-semibold py-3 px-8 rounded-full text-lg shadow-lg"
            >
              Demander une démonstration
            </motion.button>
          </motion.div>
        </div>
      </section>

      <style>
        {`
          @keyframes float {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0px); }
          }
        `}
      </style>
    </div>
  );
};

export default GeniusFramework; 
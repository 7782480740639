import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Vision = () => {
  const navigate = useNavigate();

  const visionTiles = [
    {
      id: 'notre-approche',
      title: 'Notre Approche',
      description: 'Une IA éthique, transparente et responsable qui place l\'humain au centre de sa conception et de son développement.',
      icon: '/images/ethical-ai-approach.png',
      color: 'bg-gradient-blue',
      delay: 0,
    },
    {
      id: 'souverainete',
      title: 'Souveraineté Numérique',
      description: 'Protection et contrôle local des données pour une indépendance technologique garantissant sécurité et conformité.',
      icon: '/images/digital-sovereignty.png',
      color: 'bg-gradient-red',
      delay: 0.1,
    },
    {
      id: 'valeurs',
      title: 'Excellence & Innovation',
      description: 'Engagement pour l\'excellence suisse et l\'innovation responsable au service d\'un avenir numérique durable.',
      icon: '/images/innovation-values.png',
      color: 'bg-gradient-green',
      delay: 0.2,
    }
  ];

  return (
    <section className="py-16 pt-4 overflow-hidden" id="vision">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-10"
        >
          <span 
            style={{
              background: 'linear-gradient(90deg, #0066cc, #5ac8fa)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
            className="text-xl font-medium inline-block mb-3"
          >
            Notre Vision
          </span>
          <h2 className="text-4xl md:text-5xl font-semibold mb-6 text-[#333333]">
            L'IA au Service de l'Humanité
          </h2>
          <p className="max-w-3xl mx-auto text-lg text-[#666666]">
            Chez Infinity Cloud, nous imaginons un futur où l'intelligence artificielle amplifie le potentiel humain tout en respectant nos valeurs fondamentales.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8 mt-10">
          {visionTiles.map((tile) => (
            <motion.div
              key={tile.id}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: tile.delay }}
              whileHover={{ y: -15, transition: { duration: 0.3 } }}
              className="vision-card"
              style={{
                animation: `float 6s ease-in-out infinite ${tile.delay * 2}s`,
                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                borderRadius: '24px',
                overflow: 'hidden',
                backgroundColor: 'white',
                transform: 'translateZ(0)',
                position: 'relative'
              }}
            >
              <div 
                style={{
                  background: tile.id === 'notre-approche' 
                    ? 'linear-gradient(135deg, #0066cc, #5ac8fa)' 
                    : tile.id === 'souverainete'
                      ? 'linear-gradient(135deg, #a50000, #ff2d55)'
                      : 'linear-gradient(135deg, #00991f, #30d158)',
                  padding: '20px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px'
                }}
              >
                <img 
                  src={tile.icon} 
                  alt={tile.title} 
                  className="w-20 h-20 mx-auto my-3 drop-shadow-lg"
                  style={{ display: 'block' }}
                />
              </div>
              <div style={{ padding: '24px' }}>
                <h3 style={{ fontSize: '22px', fontWeight: 600, marginBottom: '10px', color: '#333333' }}>{tile.title}</h3>
                <p style={{ fontSize: '16px', color: '#666666', lineHeight: 1.5 }}>{tile.description}</p>
                <div className="mt-5">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    style={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '36px',
                      height: '36px',
                      borderRadius: '50%',
                      backgroundColor: tile.id === 'notre-approche' 
                        ? '#0066cc' 
                        : tile.id === 'souverainete'
                          ? '#a50000'
                          : '#00991f',
                      color: 'white',
                      border: 'none',
                      padding: 0,
                      cursor: 'pointer',
                      boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
                    }}
                  >
                    <svg 
                      className="w-5 h-5" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M9 5l7 7-7 7" 
                      />
                    </svg>
                  </motion.button>
                </div>
              </div>

              {/* Effet de lumière - Temporairement commenté pour tester */}
              {/* <div 
                style={{
                  position: 'absolute',
                  width: '240px',
                  height: '240px',
                  borderRadius: '50%',
                  background: tile.id === 'notre-approche' 
                    ? 'radial-gradient(circle, rgba(92, 170, 255, 0.1) 0%, rgba(255, 255, 255, 0) 70%)' 
                    : tile.id === 'souverainete'
                      ? 'radial-gradient(circle, rgba(255, 45, 85, 0.1) 0%, rgba(255, 255, 255, 0) 70%)'
                      : 'radial-gradient(circle, rgba(48, 209, 88, 0.1) 0%, rgba(255, 255, 255, 0) 70%)',
                  zIndex: 1,
                  opacity: 0.3,
                  top: '-30px',
                  right: '-30px',
                  filter: 'blur(30px)',
                  pointerEvents: 'none'
                }}
              ></div> */}
            </motion.div>
          ))}
        </div>
      </div>

      <style>
        {`
          @keyframes float {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0px); }
          }
          
          /* Suppression de la correction de l'opacité qui pourrait causer le problème */
          /* .vision-card::after { ... } */
        `}
      </style>
    </section>
  );
};

export default Vision; 
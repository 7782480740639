import React from 'react';
import Layout from '../components/core/Layout';
import Contact from '../components/sections/Contact';

export default function ContactPage() {
  return (
    <Layout>
      <div className="min-h-screen bg-white">
        <Contact />
      </div>
    </Layout>
  );
} 
import About from '../components/sections/About';
import Impact from '../components/sections/Impact';
import Contact from '../components/sections/Contact';
import Blog from '../components/sections/Blog';
import SwissAvengers from '../components/sections/SwissAvengers';
import HeroBackground from '../components/sections/HeroBackground';
import SolutionsGrid from '../components/sections/SolutionsGrid';
import ImpactGrid from '../components/sections/ImpactGrid';
import Vision from '../components/sections/Vision';

const Home = () => {
  return (
    <main className="relative">
      {/* Fond animé avec particules - Temporairement désactivé */}
      {/* <HeroBackground /> */}
      
      {/* Commencer directement avec les tuiles colorées */}
      <section id="solutions" className="relative z-10 pt-16">
        <SolutionsGrid />
      </section>
      
      <section id="impact" className="relative z-10 py-20">
        <ImpactGrid />
      </section>
      
      <section id="vision" className="relative z-10 py-20">
        <Vision />
      </section>
      
      <section id="swiss-avengers" className="relative z-10 py-20">
        <SwissAvengers />
      </section>
      
      <section id="news" className="relative z-10 py-20">
        <Blog />
      </section>
      
      <section id="contact" className="relative z-10 py-20">
        <Contact />
      </section>
    </main>
  );
};

export default Home; 
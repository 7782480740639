import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const StradivariApp = () => {
  return (
    <div className="min-h-screen bg-white text-[#333333]">
      <Helmet>
        <title>StradivariApp | Infinity Cloud</title>
        <meta name="description" content="StradivariApp - L'intelligence artificielle redéfinit l'expression musicale avec une interface basée sur l'intention" />
      </Helmet>

      {/* Hero Section */}
      <section className="pt-20 pb-16 overflow-hidden relative">
        <div 
          className="absolute inset-0 opacity-10 z-0" 
          style={{
            backgroundImage: `url('/images/stradivari-violin.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(50px)'
          }}
        ></div>
        <div className="container mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              <span 
                style={{
                  background: 'linear-gradient(135deg, #ff9500, #ff2d55)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  paddingBottom: '0.15em',
                  lineHeight: 1.2
                }}
              >
                StradivariApp
              </span>
            </h1>
            <p className="text-xl text-[#666666] max-w-3xl mx-auto">
              L'intelligence artificielle redéfinit l'expression musicale en introduisant une interface révolutionnaire basée sur l'intention plutôt que sur la technique
            </p>
            
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.3, duration: 0.6 }}
              className="mt-12 flex justify-center"
            >
              <img 
                src="/images/stradivari-violin.png" 
                alt="StradivariApp Violin AI" 
                className="w-full max-w-2xl rounded-2xl shadow-2xl"
                style={{ 
                  animation: 'float 6s ease-in-out infinite',
                  maxWidth: '80%'
                }}
              />
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Core Concept */}
      <section className="py-16 bg-[#fff9f7]">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #ff9500, #ff2d55)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              La Fracture Expressive
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              Le Paradigme de l'Intention
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              Une approche révolutionnaire qui inverse le flux de travail traditionnel pour partir de l'intention musicale et générer automatiquement les paramètres techniques
            </p>
          </motion.div>

          <div className="flex flex-col md:flex-row gap-8 items-center">
            <motion.div
              initial={{ opacity: 0, x: -30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex-1 order-2 md:order-1"
            >
              <h3 className="text-2xl font-semibold mb-4">La Fracture Expressive</h3>
              <p className="text-[#666666] mb-6">
                Malgré la démocratisation des outils de production musicale, un défi fondamental persiste : l'écart considérable entre l'intention musicale du créateur et sa capacité à traduire cette intention en paramètres techniques qui produisent le résultat expressif souhaité.
              </p>
              
              <h3 className="text-2xl font-semibold mb-4">Une Nouvelle Approche</h3>
              <p className="text-[#666666]">
                StradivariApp inverse complètement le flux de travail traditionnel : au lieu de partir des paramètres techniques pour obtenir une expression musicale, nous partons de l'intention musicale pour générer automatiquement les paramètres techniques nécessaires.
              </p>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, x: 30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex-1 order-1 md:order-2 flex justify-center"
            >
              <img 
                src="/images/stradivari-comparison.png" 
                alt="Comparaison des approches musicales" 
                className="rounded-2xl shadow-lg w-full max-w-md"
                style={{ animation: 'float 6s ease-in-out infinite 1s' }}
              />
            </motion.div>
          </div>
        </div>
      </section>

      {/* Architecture */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #ff9500, #ff2d55)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              Architecture Technique
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              L'IA au Service de l'Expression
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              Une architecture multi-couches sophistiquée qui transforme le langage naturel en performances musicales expressives
            </p>
          </motion.div>

          <div className="flex flex-col md:flex-row gap-8 items-center">
            <motion.div
              initial={{ opacity: 0, x: 30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex-1 flex justify-center"
            >
              <img 
                src="/images/stradivari-interface.png" 
                alt="Interface StradivariApp" 
                className="rounded-2xl shadow-lg w-full max-w-md"
                style={{ animation: 'float 6s ease-in-out infinite 2s' }}
              />
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, x: -30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex-1"
            >
              <div className="mb-8">
                <h3 className="text-2xl font-semibold mb-3">IA Conversationnelle Spécialisée</h3>
                <p className="text-[#666666]">
                  Un modèle d'intelligence artificielle entraîné spécifiquement sur les relations entre le langage naturel décrivant des intentions musicales et les paramètres techniques correspondants.
                </p>
              </div>
              
              <div className="mb-8">
                <h3 className="text-2xl font-semibold mb-3">Moteur de Traduction Paramétrique</h3>
                <p className="text-[#666666]">
                  Un système qui convertit les intentions interprétées en configurations précises de paramètres MIDI, d'articulations et de techniques de jeu avec une latence inférieure à 20ms.
                </p>
              </div>
              
              <div>
                <h3 className="text-2xl font-semibold mb-3">Architecture d'Itération Expressive</h3>
                <p className="text-[#666666]">
                  Un framework permettant d'affiner progressivement l'expression musicale à travers un dialogue itératif entre l'utilisateur et l'IA.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Key Features */}
      <section className="py-16 bg-[#fff9f7]">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #ff9500, #ff2d55)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              Caractéristiques
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              Innovations Technologiques
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              Un ensemble de technologies avancées qui redéfinissent l'interaction entre les musiciens et les instruments virtuels
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <div 
                className="w-12 h-12 flex items-center justify-center rounded-full mb-4"
                style={{ background: 'linear-gradient(135deg, #ff9500, #ff2d55)' }}
              >
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Dictionnaire Expressif</h3>
              <p className="text-[#666666]">
                Un dictionnaire propriétaire avec plus de 5 000 mappings expressifs couvrant 127 articulations, 84 techniques de jeu, et 212 descripteurs émotionnels avec leurs traductions paramétriques.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <div 
                className="w-12 h-12 flex items-center justify-center rounded-full mb-4"
                style={{ background: 'linear-gradient(135deg, #ff9500, #ff2d55)' }}
              >
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Traduction Paramétrique</h3>
              <p className="text-[#666666]">
                Un moteur qui génère simultanément des courbes de vélocité expressives, une modulation dynamique, un contrôle nuancé du vibrato et des variations micro-temporelles pour une expressivité optimale.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <div 
                className="w-12 h-12 flex items-center justify-center rounded-full mb-4"
                style={{ background: 'linear-gradient(135deg, #ff9500, #ff2d55)' }}
              >
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Dialogue Itératif</h3>
              <p className="text-[#666666]">
                Un système permettant aux utilisateurs d'affiner progressivement l'interprétation musicale à travers un dialogue naturel avec l'IA, pour un contrôle expressif sans précédent.
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Impact */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #ff9500, #ff2d55)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              Impact
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              Révolution du Flux de Travail
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              Une transformation radicale des processus de création musicale avec des résultats impressionnants dans divers contextes
            </p>
          </motion.div>

          <div className="bg-white rounded-[24px] shadow-lg p-8"
            style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <h3 className="text-xl font-semibold mb-3">Composition pour Médias</h3>
                <ul className="space-y-2 text-[#666666]">
                  <li>• Réduction de 87% du temps de production</li>
                  <li>• Exploration rapide des variations émotionnelles</li>
                  <li>• Focus sur l'intention plutôt que la technique</li>
                  <li>• Maquettes orchestrales expressives en minutes</li>
                  <li>• Adoption par des compositeurs professionnels</li>
                </ul>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <h3 className="text-xl font-semibold mb-3">Production Musicale</h3>
                <ul className="space-y-2 text-[#666666]">
                  <li>• +64% d'utilisation d'instruments à cordes virtuels</li>
                  <li>• Démocratisation des instruments complexes</li>
                  <li>• Accessibilité pour tous niveaux techniques</li>
                  <li>• Réduction de 95% du temps de configuration</li>
                  <li>• Fidélité expressive sans expertise technique</li>
                </ul>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <h3 className="text-xl font-semibold mb-3">Éducation Musicale</h3>
                <ul className="space-y-2 text-[#666666]">
                  <li>• +38% performances en analyse interprétative</li>
                  <li>• Outil pédagogique puissant dans les conservatoires</li>
                  <li>• Compréhension approfondie de l'interprétation</li>
                  <li>• Démocratisation de l'expression musicale nuancée</li>
                  <li>• Courbe d'apprentissage réduite de 99%</li>
                </ul>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      {/* Roadmap */}
      <section className="py-16 bg-[#fff9f7]">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #ff9500, #ff2d55)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              Perspectives d'Avenir
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              Feuille de Route Technologique
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              La vision à long terme de StradivariApp s'articule autour de trois axes de développement innovants
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <h3 className="text-xl font-semibold mb-3">Expansion Multi-instrumentale</h3>
              <p className="text-[#666666]">
                Extension à l'ensemble des familles instrumentales, avec une attention particulière aux instruments à vent et aux voix humaines dans les 12 prochains mois pour une couverture expressive complète.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <h3 className="text-xl font-semibold mb-3">IA Générative Guidée par l'Intention</h3>
              <p className="text-[#666666]">
                Intégration de capacités de génération musicale guidées par l'intention expressive, permettant aux utilisateurs de générer du contenu musical aligné sur leurs intentions expressives.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <h3 className="text-xl font-semibold mb-3">Écosystème Collaboratif</h3>
              <p className="text-[#666666]">
                Développement d'une API ouverte permettant l'intégration de StradivariApp dans des workflows collaboratifs, facilitant la communication des intentions expressives entre compositeurs, interprètes et producteurs.
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Call To Action */}
      <section className="py-16 bg-gradient-to-r from-[#ff9500] to-[#ff2d55] text-white">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl font-bold mb-6">Une Nouvelle Ère de l'Expression Musicale</h2>
            <p className="max-w-2xl mx-auto text-lg mb-8 text-white/90">
              Découvrez comment StradivariApp peut transformer radicalement votre approche de la création musicale en plaçant l'intention expressive au centre du processus créatif.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-white text-[#ff2d55] font-semibold py-3 px-8 rounded-full text-lg shadow-lg"
            >
              Demander une démonstration
            </motion.button>
          </motion.div>
        </div>
      </section>

      <style>
        {`
          @keyframes float {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0px); }
          }
        `}
      </style>
    </div>
  );
};

export default StradivariApp; 
import { motion } from 'framer-motion';
import { FormEvent, useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';

interface FormState {
  name: string;
  email: string;
  organization: string;
  message: string;
}

const Contact = () => {
  const [formState, setFormState] = useState<FormState>({
    name: '',
    email: '',
    organization: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');

  // Réinitialiser le statut après un certain temps lors d'un succès
  useEffect(() => {
    if (submitStatus === 'success') {
      const timer = setTimeout(() => {
        setSubmitStatus('idle');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [submitStatus]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: formState.name,
          email: formState.email,
          subject: formState.organization || 'Message depuis le site web',
          message: formState.message
        })
      });
      
      if (response.ok) {
        toast.success('Message envoyé avec succès !');
        setFormState({ name: '', email: '', organization: '', message: '' });
        setSubmitStatus('success');
      } else {
        const data = await response.json();
        throw new Error(data.message || 'Échec de l\'envoi du message');
      }
    } catch (error) {
      console.error('Erreur:', error);
      toast.error('Une erreur est survenue lors de l\'envoi du message');
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <section id="contact" className="py-20 relative overflow-hidden">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <span className="text-xl font-medium inline-block mb-3"
            style={{
              background: 'linear-gradient(90deg, #0066cc, #5ac8fa)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}>
            Contactez-nous
          </span>
          <h2 className="text-4xl md:text-5xl font-semibold mb-6 text-[#333333]">
            Get in Touch
          </h2>
          <p className="max-w-3xl mx-auto text-lg text-[#666666]">
            Vous souhaitez en savoir plus sur nos solutions d'IA ? Nous serions ravis d'échanger avec vous.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Informations de contact */}
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            whileHover={{ y: -10, transition: { duration: 0.3 } }}
            style={{
              animation: 'float 6s ease-in-out infinite',
              boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
              borderRadius: '24px',
              overflow: 'hidden',
              backgroundColor: 'white',
              transform: 'translateZ(0)',
              position: 'relative'
            }}
          >
            <div 
              style={{
                background: 'linear-gradient(135deg, #0066cc, #5ac8fa)',
                padding: '20px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px'
              }}
            >
              <img 
                src="/images/edge-ai-icon.png" 
                alt="Contact Information" 
                className="w-20 h-20 mx-auto my-3 drop-shadow-lg"
                style={{ display: 'block' }}
              />
            </div>
            
            <div style={{ padding: '24px' }}>
              <h3 style={{ fontSize: '22px', fontWeight: 600, marginBottom: '16px', color: '#333333' }}>Informations de Contact</h3>
              
              <div className="space-y-6">
                <div>
                  <h4 style={{ fontSize: '18px', fontWeight: 500, marginBottom: '8px', color: '#444444' }}>Emplacement</h4>
                  <p style={{ color: '#666666', lineHeight: '1.6' }}>
                    98bis chemin de la Mère Voie<br />
                    Plan-Les-Ouates, Geneva<br />
                    Switzerland
                  </p>
                </div>

                <div>
                  <h4 style={{ fontSize: '18px', fontWeight: 500, marginBottom: '8px', color: '#444444' }}>Réseaux Sociaux</h4>
                  <div className="flex space-x-4">
                    <a
                      href="https://linkedin.com/in/mdanggva/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#0071e3' }}
                      className="hover:opacity-80 transition-opacity"
                    >
                      <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                      </svg>
                    </a>
                    <a
                      href="https://soundcloud.com/mrdgeneva"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#0071e3' }}
                      className="hover:opacity-80 transition-opacity"
                    >
                      <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M7 17.939h-1v-8.068c.308-.231.639-.429 1-.566v8.634zm3 0h1v-9.224c-.229.265-.443.548-.621.857l-.379-.184v8.551zm-2 0h1v-8.848c-.508-.079-.623-.05-1-.01v8.858zm-4 0h1v-7.02c-.312.458-.555.971-.692 1.535l-.308-.182v5.667zm-3-5.25c-.606.547-1 1.354-1 2.268 0 .914.394 1.721 1 2.268v-4.536zm18.879-.671c-.204-2.837-2.404-5.079-5.117-5.079-1.022 0-1.964.328-2.762.877v10.123h9.089c1.607 0 2.911-1.393 2.911-3.106 0-2.233-2.168-3.772-4.121-2.815zm-16.879-.027c-.302-.024-.526-.03-1 .122v5.689c.446.143.636.138 1 .138v-5.949z"/>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Effet de lumière */}
            <div 
              style={{
                position: 'absolute',
                width: '300px',
                height: '300px',
                borderRadius: '50%',
                background: 'radial-gradient(circle, rgba(92, 170, 255, 0.15) 0%, rgba(255, 255, 255, 0) 70%)',
                zIndex: -1,
                opacity: 0.6,
                top: '-40px',
                right: '-40px',
                filter: 'blur(40px)'
              }}
            ></div>
          </motion.div>

          {/* Formulaire de contact */}
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.1 }}
            whileHover={{ y: -10, transition: { duration: 0.3 } }}
            style={{
              animation: 'float 6s ease-in-out infinite 0.5s',
              boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
              borderRadius: '24px',
              overflow: 'hidden',
              backgroundColor: 'white',
              transform: 'translateZ(0)',
              position: 'relative'
            }}
          >
            <div 
              style={{
                background: 'linear-gradient(135deg, #5e5ce6, #bf5af2)',
                padding: '20px',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px'
              }}
            >
              <img 
                src="/images/genius-ai-icon.png" 
                alt="Contact Form" 
                className="w-20 h-20 mx-auto my-3 drop-shadow-lg"
                style={{ display: 'block' }}
              />
            </div>
            
            <form onSubmit={handleSubmit} style={{ padding: '24px' }}>
              <div className="space-y-6">
                <div>
                  <label htmlFor="name" style={{ display: 'block', fontSize: '14px', fontWeight: 500, marginBottom: '8px', color: '#444444' }}>
                    Nom
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formState.name}
                    onChange={handleChange}
                    style={{
                      width: '100%',
                      padding: '12px 16px',
                      borderRadius: '12px',
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#f9f9f9',
                      fontSize: '15px',
                      color: '#333333',
                      transition: 'border-color 0.2s ease',
                    }}
                    className="focus:outline-none focus:border-blue-400"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="email" style={{ display: 'block', fontSize: '14px', fontWeight: 500, marginBottom: '8px', color: '#444444' }}>
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formState.email}
                    onChange={handleChange}
                    style={{
                      width: '100%',
                      padding: '12px 16px',
                      borderRadius: '12px',
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#f9f9f9',
                      fontSize: '15px',
                      color: '#333333',
                      transition: 'border-color 0.2s ease',
                    }}
                    className="focus:outline-none focus:border-blue-400"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="organization" style={{ display: 'block', fontSize: '14px', fontWeight: 500, marginBottom: '8px', color: '#444444' }}>
                    Organisation
                  </label>
                  <input
                    type="text"
                    id="organization"
                    name="organization"
                    value={formState.organization}
                    onChange={handleChange}
                    style={{
                      width: '100%',
                      padding: '12px 16px',
                      borderRadius: '12px',
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#f9f9f9',
                      fontSize: '15px',
                      color: '#333333',
                      transition: 'border-color 0.2s ease',
                    }}
                    className="focus:outline-none focus:border-blue-400"
                  />
                </div>

                <div>
                  <label htmlFor="message" style={{ display: 'block', fontSize: '14px', fontWeight: 500, marginBottom: '8px', color: '#444444' }}>
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formState.message}
                    onChange={handleChange}
                    rows={4}
                    style={{
                      width: '100%',
                      padding: '12px 16px',
                      borderRadius: '12px',
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#f9f9f9',
                      fontSize: '15px',
                      color: '#333333',
                      transition: 'border-color 0.2s ease',
                      resize: 'none'
                    }}
                    className="focus:outline-none focus:border-blue-400"
                    required
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    width: '100%',
                    padding: '14px 24px',
                    backgroundColor: '#0071e3',
                    color: 'white',
                    border: 'none',
                    borderRadius: '12px',
                    fontWeight: 500,
                    fontSize: '16px',
                    cursor: isSubmitting ? 'not-allowed' : 'pointer',
                    opacity: isSubmitting ? 0.7 : 1,
                    transition: 'all 0.2s ease',
                  }}
                  className="hover:bg-blue-600 active:bg-blue-700"
                >
                  {isSubmitting ? 'Envoi en cours...' : 'Envoyer le message'}
                </button>

                {submitStatus === 'success' && (
                  <p style={{ textAlign: 'center', color: '#10b981' }} className="mt-4">
                    Merci pour votre message ! Nous vous répondrons rapidement.
                  </p>
                )}

                {submitStatus === 'error' && (
                  <p style={{ textAlign: 'center', color: '#ef4444' }} className="mt-4">
                    Une erreur s'est produite. Veuillez réessayer ultérieurement.
                  </p>
                )}
              </div>
            </form>

            {/* Effet de lumière */}
            <div 
              style={{
                position: 'absolute',
                width: '300px',
                height: '300px',
                borderRadius: '50%',
                background: 'radial-gradient(circle, rgba(191, 90, 242, 0.15) 0%, rgba(255, 255, 255, 0) 70%)',
                zIndex: -1,
                opacity: 0.6,
                top: '-40px',
                right: '-40px',
                filter: 'blur(40px)'
              }}
            ></div>
          </motion.div>
        </div>
      </div>

      <style>
        {`
          @keyframes float {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0px); }
          }
        `}
      </style>
    </section>
  );
};

export default Contact;

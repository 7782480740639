import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './styles/color-override.css';
import { Toaster } from 'react-hot-toast';

// Create root element with type assertion for strict mode
const rootElement = document.getElementById('root') as HTMLElement;

// Create root and render app
ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <App />
    <Toaster position="top-right" toastOptions={{
      duration: 4000,
      style: {
        background: '#fff',
        color: '#333',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        padding: '12px 16px'
      },
      success: {
        style: {
          border: '1px solid rgba(34, 197, 94, 0.2)',
          borderLeft: '4px solid #22c55e'
        }
      },
      error: {
        style: {
          border: '1px solid rgba(239, 68, 68, 0.2)',
          borderLeft: '4px solid #ef4444'
        }
      }
    }} />
  </React.StrictMode>
);

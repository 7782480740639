import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const EdgeIntelligence = () => {
  return (
    <div className="min-h-screen bg-white text-[#333333]">
      <Helmet>
        <title>RAID 0 Neuronal By Mr D. | Infinity Cloud</title>
        <meta name="description" content="RAID 0 Neuronal By Mr D. - Technologie d'IA distribuée ultra-performante avec Apple Silicon" />
      </Helmet>

      {/* Hero Section */}
      <section className="pt-20 pb-16 overflow-hidden relative">
        <div 
          className="absolute inset-0 opacity-10 z-0" 
          style={{
            backgroundImage: `url('/images/edge-distributed-network.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(50px)'
          }}
        ></div>
        <div className="container mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              <span 
                style={{
                  background: 'linear-gradient(135deg, #0066cc, #5ac8fa)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  paddingBottom: '0.15em',
                  lineHeight: 1.2
                }}
              >
                RAID 0 Neuronal By Mr D.
              </span>
            </h1>
            <p className="text-xl text-[#666666] max-w-3xl mx-auto">
              La révolution de l'intelligence artificielle distribuée avec notre technologie RAID 0 Neuronal pour des performances ultra-rapides et une efficacité énergétique maximale
            </p>
            
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.3, duration: 0.6 }}
              className="mt-12 flex justify-center"
            >
              <img 
                src="/images/raid0-neuronal.png" 
                alt="RAID 0 Neuronal System" 
                className="w-full max-w-2xl rounded-2xl shadow-2xl"
                style={{ 
                  animation: 'float 6s ease-in-out infinite',
                  maxWidth: '80%'
                }}
              />
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Core Concept */}
      <section className="py-16 bg-[#f8faff]">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #0066cc, #5ac8fa)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              Technologie Révolutionnaire
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              RAID 0 Neuronal
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              Repensez les fondamentaux de l'IA avec une approche distribuée qui surpasse les architectures traditionnelles en performance et en efficacité
            </p>
          </motion.div>

          <div className="flex flex-col md:flex-row gap-8 items-center">
            <motion.div
              initial={{ opacity: 0, x: -30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex-1 order-2 md:order-1"
            >
              <h3 className="text-2xl font-semibold mb-4">Distribution Neuronale Avancée</h3>
              <p className="text-[#666666] mb-6">
                Notre technologie RAID 0 Neuronal exploite la puissance des puces Apple Silicon en distribuant intelligemment la charge cognitive sur plusieurs appareils, créant ainsi un "cerveau distribué" capable de performances exceptionnelles.
              </p>
              
              <h3 className="text-2xl font-semibold mb-4">La Synergie au lieu de la Force Brute</h3>
              <p className="text-[#666666]">
                Contrairement aux approches traditionnelles qui misent sur des modèles toujours plus grands, notre approche disruptive mise sur l'intelligence distribuée pour atteindre une efficacité supérieure, tout comme le cerveau humain tire sa puissance de connexions neuronales plutôt que de volume brut.
              </p>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, x: 30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex-1 order-1 md:order-2 flex justify-center"
            >
              <img 
                src="/images/edge-distributed-network.png" 
                alt="Réseau neuronal distribué" 
                className="rounded-2xl shadow-lg w-full max-w-md"
                style={{ animation: 'float 6s ease-in-out infinite 1s' }}
              />
            </motion.div>
          </div>
        </div>
      </section>

      {/* Performance Benefits */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #0066cc, #5ac8fa)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              Avantages Mesurables
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              Performances Sans Précédent
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              Des résultats empiriques qui démontrent une supériorité incontestable face aux architectures traditionnelles
            </p>
          </motion.div>

          <div className="flex flex-col md:flex-row gap-8 items-center">
            <motion.div
              initial={{ opacity: 0, x: 30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex-1 flex justify-center"
            >
              <img 
                src="/images/edge-performance.png" 
                alt="Comparaison de performances" 
                className="rounded-2xl shadow-lg w-full max-w-md"
                style={{ animation: 'float 6s ease-in-out infinite 2s' }}
              />
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, x: -30 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex-1"
            >
              <div className="mb-8">
                <h3 className="text-2xl font-semibold mb-3">Performances Exceptionnelles</h3>
                <p className="text-[#666666]">
                  Augmentation des performances jusqu'à 287% comparé aux serveurs GPU traditionnels, avec un temps d'inférence réduit de 65% en configuration RAID 0 Neuronal à 4 appareils.
                </p>
              </div>
              
              <div className="mb-8">
                <h3 className="text-2xl font-semibold mb-3">Économies Substantielles</h3>
                <p className="text-[#666666]">
                  Réduction des coûts d'infrastructure de 73%, avec un investissement initial nettement inférieur aux solutions traditionnelles tout en offrant des performances supérieures.
                </p>
              </div>
              
              <div>
                <h3 className="text-2xl font-semibold mb-3">Efficacité Énergétique</h3>
                <p className="text-[#666666]">
                  Consommation énergétique réduite de 87%, passant de 1450W pour un serveur GPU standard à seulement 190W pour un système RAID 0 Neuronal à 4 appareils.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Key Features */}
      <section className="py-16 bg-[#f8faff]">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #0066cc, #5ac8fa)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              Fonctionnalités
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              Technologies Innovantes
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              Un ensemble de technologies de pointe qui redéfinissent les limites de l'IA
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <div 
                className="w-12 h-12 flex items-center justify-center rounded-full mb-4"
                style={{ background: 'linear-gradient(135deg, #0066cc, #5ac8fa)' }}
              >
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Thunderbolt Fusionné</h3>
              <p className="text-[#666666]">
                Exploitation de la technologie Thunderbolt pour créer des connexions à 40 Gb/s entre appareils, permettant une synchronisation en temps réel de la mémoire unifiée.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <div 
                className="w-12 h-12 flex items-center justify-center rounded-full mb-4"
                style={{ background: 'linear-gradient(135deg, #0066cc, #5ac8fa)' }}
              >
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14-6H5a2 2 0 00-2 2v6a2 2 0 002 2h14a2 2 0 002-2V7a2 2 0 00-2-2" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Segmentation de Tenseurs</h3>
              <p className="text-[#666666]">
                Distribution automatique des tenseurs sur plusieurs appareils pour un traitement parallèle optimal, augmentant significativement les performances de calcul.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <div 
                className="w-12 h-12 flex items-center justify-center rounded-full mb-4"
                style={{ background: 'linear-gradient(135deg, #0066cc, #5ac8fa)' }}
              >
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Souveraineté des Données</h3>
              <p className="text-[#666666]">
                Fonctionnement entièrement on-premise, sans dépendance au cloud, garantissant un contrôle total sur vos données et une conformité réglementaire optimale.
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Technical Specifications */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #0066cc, #5ac8fa)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              Spécifications
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              Architecture Technique
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              Une infrastructure robuste conçue pour des performances optimales et une sécurité maximale
            </p>
          </motion.div>

          <div className="bg-white rounded-[24px] shadow-lg p-8"
            style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <h3 className="text-xl font-semibold mb-3">Configurations</h3>
                <ul className="space-y-2 text-[#666666]">
                  <li>• RAID 0 Neuronal (2 appareils)</li>
                  <li>• RAID 0 Neuronal (4 appareils)</li>
                  <li>• Configuration mixte Mac Studio/MacBook Pro</li>
                  <li>• Supporte jusqu'à 8 appareils en cluster</li>
                  <li>• Extensible via Thunderbolt Daisy Chain</li>
                </ul>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <h3 className="text-xl font-semibold mb-3">Performances</h3>
                <ul className="space-y-2 text-[#666666]">
                  <li>• Modèles jusqu'à 70 milliards de paramètres</li>
                  <li>• Vitesse d'inférence quasi-linéaire</li>
                  <li>• Calcul de matrices d'attention en parallèle</li>
                  <li>• Prise en charge LLM, CNN, Transformers</li>
                  <li>• Pipeline pour MLOps distribué</li>
                </ul>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <h3 className="text-xl font-semibold mb-3">Sécurité</h3>
                <ul className="space-y-2 text-[#666666]">
                  <li>• Chiffrement matériel avec Apple T2</li>
                  <li>• Secure Enclave pour les clés</li>
                  <li>• Isolation des tenseurs sensibles</li>
                  <li>• Vérification d'intégrité constante</li>
                  <li>• Conformité GDPR et CCPA par défaut</li>
                </ul>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      {/* Strategic Implications */}
      <section className="py-16 bg-[#f8faff]">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <span 
              style={{
                background: 'linear-gradient(135deg, #0066cc, #5ac8fa)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
              className="text-xl font-medium inline-block mb-3"
            >
              Impact Stratégique
            </span>
            <h2 className="text-4xl font-semibold mb-6 text-[#333333]">
              Avantage Concurrentiel Décisif
            </h2>
            <p className="max-w-2xl mx-auto text-lg text-[#666666]">
              Adoptez dès aujourd'hui la technologie qui redéfinira l'industrie de l'IA de demain
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <h3 className="text-xl font-semibold mb-3">Démocratisation de l'IA</h3>
              <p className="text-[#666666]">
                Déployez des modèles de 70 milliards de paramètres sur du matériel standard, rendant accessible l'IA de pointe à toutes les organisations, quelle que soit leur taille. Éliminez les barrières d'entrée liées aux coûts d'infrastructure prohibitifs.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <h3 className="text-xl font-semibold mb-3">ROI Accéléré</h3>
              <p className="text-[#666666]">
                Réduction de 73% des coûts d'infrastructure tout en améliorant les performances, permettant un retour sur investissement rapide. La baisse significative de la consommation énergétique contribue également à réduire les coûts opérationnels à long terme.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <h3 className="text-xl font-semibold mb-3">Agilité Technologique</h3>
              <p className="text-[#666666]">
                Évolutivité sans remplacer l'infrastructure existante, permettant d'adapter facilement la puissance de calcul en fonction des besoins. Ajoutez simplement de nouveaux appareils au cluster pour augmenter les performances de manière quasi-linéaire.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="bg-white rounded-[24px] shadow-lg p-6 hover:shadow-xl transition-shadow"
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' }}
            >
              <h3 className="text-xl font-semibold mb-3">Soutenabilité Environnementale</h3>
              <p className="text-[#666666]">
                Réduction drastique de l'empreinte carbone grâce à une efficacité énergétique supérieure. La consommation réduite de 87% par rapport aux solutions traditionnelles contribue significativement aux objectifs de développement durable de votre organisation.
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Call To Action */}
      <section className="py-16 bg-gradient-to-r from-[#0066cc] to-[#5ac8fa] text-white">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl font-bold mb-6">L'avenir de l'IA est distribué</h2>
            <p className="max-w-2xl mx-auto text-lg mb-8 text-white/90">
              Rejoignez la révolution de l'intelligence artificielle distribuée et découvrez comment Edge Intelligence peut transformer radicalement votre approche de l'IA.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-white text-blue-700 font-semibold py-3 px-8 rounded-full text-lg shadow-lg"
            >
              Planifier une démonstration
            </motion.button>
          </motion.div>
        </div>
      </section>

      <style>
        {`
          @keyframes float {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0px); }
          }
        `}
      </style>
    </div>
  );
};

export default EdgeIntelligence; 